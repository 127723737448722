import { TypographyV2 } from "@components/index";
import React, { FC } from "react";
import * as styles from "./_TitleWithImage.module.scss";
import { StaticImage } from "gatsby-plugin-image";

interface TitleWithImageProps {
}

const TitleWithImage: FC<TitleWithImageProps> = (props) => {
	return (
		<div className={styles.sectionContainer}>
			<TypographyV2
				variant="HEADING_2"
				className={styles.heading}
			>
				Centralize all research processes within a single, comprehensive
				software solution
			</TypographyV2>
			<div className={styles.banner}>
				<StaticImage
					src="../../_assets/software-solution.png"
					alt="comprehensive software solution"
					loading="eager"
					className={styles.bannerLg}
				/>
				<StaticImage
					src="../../_assets/software-solution-sm.png"
					alt="comprehensive software solution"
					loading="eager"
					className={styles.bannerSm}
				/>
			</div>
			<TypographyV2
				variant="BODY_TEXT_EXTRA_LARGE"
				className={styles.tagline}
				color="text-helper"
				center
			>
				Enhance clinical research breakthroughs with Genemod's
				integrated approach to data management.{" "}
			</TypographyV2>
		</div>
	);
};

export default TitleWithImage;
