import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Hero } from "@components/Hero/Hero";
import * as styles from "./_ConsumablesHero.module.scss";

export const HeroConsumables = () => {
	const props = {
		title: "Streamline Your Consumables Management",
		description:
			"Maximize your research efficiency with LIMS software that’s tailor-made for tracking biopharma consumables.",
		image: (
			<StaticImage
				loading="eager"
				src="../../_assets/consumable-layout.png"
				alt="Genemod Consumables Hero Image"
				className={styles.image}
			/>
		),
		className: styles.heroContainer,
		wrapperClassName: styles.wrapperClassName,
	};

	return (
		<div className={styles.sectionWrapper}>
			<Hero generalSansFont {...props} />
		</div>
	);
};
